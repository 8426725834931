import React, { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Icon, IconButton, InputAdornment, Link } from '@mui/material';

import Typography from '@mui/material/Typography';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const App = () => {

  const [userCredentials, setUserCredentials] = useState({ email: "", password: "" });
  const { email, password } = userCredentials;
  const [showOrganizations, setShowOrganizations] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);


  const onClickLogin = async () => {
    let flagWhites = true;
    let flagGeneral = true;

    if (password === "" || email === "") {
      flagWhites = false;
    }
    if (!flagWhites) {
      flagGeneral = false;

    }
    if (flagGeneral) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        alert("Datos incorrectos. Intente nuevamente.");
      }, 2000);


    } else {
      alert("Porfavor ingresar todos los campos");
    }
  }
  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickLogin()
    }
  }

  return (
    <>
      {
        <Grid container component="main" sx={{
          height: '100vh'
        }}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7}
            sx={{
              backgroundImage: 'url(/login_background.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <img src="/logo.png" alt="logo" width={"70px"} />
              <Box pt={1}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type={hiddenPassword ? 'password' : 'text'}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <IconButton
                          onClick={() => setHiddenPassword(!hiddenPassword)}
                        >
                          {hiddenPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#384DD1" }}
                  onClick={onClickLogin}
                  disabled={loading}
                >Ingresar</Button>
                <Grid container>
                  <Grid item xs>
                  </Grid>
                  <Grid item>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid >
      }
    </>
  );
}

export default App;